@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap');

/* ------------------------------------------------------
   1. General Resets & Body
-------------------------------------------------------- */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  background: #fefefe;
  color: #111;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
}

/* ------------------------------------------------------
   2. Signup Container & Box
-------------------------------------------------------- */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background: #fefefe;
  min-height: 100vh;
}

.signup-box {
  background: #ffffff;
  width: 100%;
  max-width: 480px;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  padding: 32px;
  animation: fadeInUp 0.4s ease-out both;
}

/* ------------------------------------------------------
   3. Title and Subtitle
-------------------------------------------------------- */
.signup-title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
}

.signup-subtitle {
  font-size: 1rem;
  text-align: center;
  color: #666;
  margin-bottom: 24px;
}

/* ------------------------------------------------------
   4. Form Styling
-------------------------------------------------------- */
.signup-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-label {
  display: block;
  font-weight: 500;
  margin-bottom: 4px;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 12px 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
  background-color: #fafafa;
}

.input-field:focus {
  outline: none;
  border-color: #007aff;
  background-color: #fff;
}

/* ------------------------------------------------------
   5. Plan Selection Section
-------------------------------------------------------- */
.plans-container {
  margin-top: 8px;
}

.plan-label {
  font-weight: 500;
  margin-bottom: 8px;
  color: #333;
}

.plan-options {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.plan-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border: 2px solid #ccc;
  border-radius: 12px;
  cursor: pointer;
  transition: border-color 0.2s, box-shadow 0.2s;
  width: calc(33.33% - 8px);
  background-color: #fafafa;
}

.plan-card:hover {
  border-color: #007aff;
}

.plan-selected {
  border-color: #007aff !important;
  box-shadow: 0 4px 16px rgba(0, 122, 255, 0.15);
  background-color: #ffffff;
}

.plan-radio {
  position: absolute;
  opacity: 0;
}

.plan-title {
  font-size: 1rem;
  font-weight: 500;
  pointer-events: none;
  color: #333;
}

/* ------------------------------------------------------
   6. Submit Button
-------------------------------------------------------- */
.submit-button {
  padding: 14px 18px;
  border: none;
  border-radius: 8px;
  background-color: #007aff;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 8px;
}

.submit-button:hover {
  background-color: #005bbf;
}

.submit-button:disabled {
  background-color: #a4c8f0;
  cursor: not-allowed;
}

/* ------------------------------------------------------
   7. Error Message
-------------------------------------------------------- */
.error-message {
  background: #ffd9d9;
  color: #a10000;
  padding: 12px;
  border-radius: 6px;
  font-size: 0.95rem;
  text-align: center;
}

/* ------------------------------------------------------
   8. Welcome View (Integrated into Signup Process)
-------------------------------------------------------- */
.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 100%;
  height: 100vh;
}

.welcome-message {
  text-align: center;
}

/* Logo Reveal Animation */
.welcome-logo {
  width: 300px; /* Adjust size as needed */
  max-width: 80%;
  opacity: 0;
  transform: scale(0.8);
  animation: revealLogo 1.5s ease-out forwards;
  margin: 0 auto;
}

@keyframes revealLogo {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Verification note styling */
.verification-note {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #777;
  opacity: 0;
  animation: fadeIn 1.5s ease-out forwards;
  animation-delay: 1.5s;
}

@keyframes fadeIn {
  to { opacity: 1; }
}

/* ------------------------------------------------------
   9. Animations for Form Entrance
-------------------------------------------------------- */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/* ------------------------------------------------------
   10. Responsive Adjustments
-------------------------------------------------------- */
@media (max-width: 600px) {
  .signup-box {
    padding: 24px;
  }
  .plan-options {
    flex-direction: column;
    gap: 12px;
  }
  .plan-card {
    width: 100%;
  }
  .signup-title {
    font-size: 1.8rem;
  }
  .signup-subtitle {
    font-size: 0.95rem;
  }
  .input-field {
    font-size: 0.95rem;
    padding: 10px 12px;
  }
  .submit-button {
    font-size: 0.95rem;
    padding: 12px 16px;
  }
  .welcome-logo {
    width: 200px;
  }
}
