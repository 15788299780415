/* App.css */

:root {
    --background-default: #000;
    --background-paper: rgba(255, 255, 255, 0.2);
    --primary-main: #0071E3;
    --secondary-main: #E53935;
    --text-primary: #ffffff;
    --text-secondary: #aaaaaa;
    --border-color: rgba(255, 255, 255, 0.3);
    --hover-bg-color: rgba(0, 113, 227, 0.1);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

body {
    /* Remove the global background color */
    color: var(--text-primary);
    font-size: 14px;
    overflow-x: hidden;
}

.card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 12px;
    color: var(--primary-main);
}

.loading-container,
.no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.search-page-container {
    background-color: white; /* Specific background color for the search page */
}
.dashboard-container {
    background-color: var(--background-default); /* Default dark background for dashboard */
}
.glass-card {
    background: var(--background-paper);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid var(--border-color);
    padding: 16px;
    margin: 16px 0;
}

.generate-report-button {
    background-color: var(--primary-main);
    color: var(--text-primary);
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}

.generate-report-button:hover {
    background-color: rgba(0, 113, 227, 0.8);
}

.table-cell {
    color: var(--text-primary);
    font-size: 0.875rem;
    padding: 16px;
}

.table-header {
    background-color: var(--background-default);
    color: var(--text-primary);
    font-weight: bold;
}

.table-row:hover {
    background-color: var(--hover-bg-color);
    color: var(--text-primary);
    cursor: pointer;
}

.table-container {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid var(--border-color);
}

.table {
    background-color: var(--background-default);
    color: var(--text-primary);
}

a {
    color: var(--primary-main);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.card {
    background: var(--background-paper);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid var(--border-color);
    overflow: hidden;
    margin: 16px 0;
    transition: transform 0.3s;
}

.card:hover {
    transform: translateY(-5px);
}

.card-content {
    color: var(--text-primary);
    padding: 16px;
}

.listItem {
    transition: background-color 0.3s;
}

.listItem:hover {
    background-color: var(--primary-main);
    color: var(--text-primary);
}


/* Media Queries */

@media (max-width: 600px) {
    .dashboard-container {
        padding: 12px;
    }
    .card-title {
        font-size: 1.25rem;
    }
    .generate-report-button {
        padding: 12px 24px;
        font-size: 1.125rem;
    }
}

@media (max-width: 480px) {
    body {
        font-size: 16px;
    }
    .dashboard-container,
    .glass-card {
        padding: 8px;
    }
    .table-cell {
        padding: 12px;
    }
}